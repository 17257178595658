//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
  }
}

// Pagination datatable
.inner-custom-pagination {
  .pagination.react-bootstrap-table-page-btns-ul {
    .page-item {
      &.active a.page-link {
        background-color: $green-koendang;
        border-color: $green-koendang;
        color: $gold-koendang;
      }
      &:not(.active) a.page-link {
        &:hover {
          color: $green-koendang;
        }
      }
    }
  }
  .react-bs-table-sizePerPage-dropdown.dropdown {
    #pageDropDown {
      background-color: $green-koendang;
      border-color: $green-koendang;
      color: $gold-koendang !important;
      span.caret {
        display: none;
      }
    }
    ul.dropdown-menu {
      li > a {
        color: $green-koendang;
      }
    }
  }
}
