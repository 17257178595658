$primary: #425d58;
$secondary: #ffcea3;
$light: #fdfdfd;
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$gray-600: #74788d;
$body-color: $gray-700;
$card-bg: $white;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  // "success": $success,
  // "info": $info,
  // "warning": $warning,
  // "danger": $danger,
  // "pink": $pink,
  "light": $light,
  // "dark": $dark,
);

// custom
.auth-koendang-logo {
  svg {
    width: 34px;
    fill: $primary;
  }
}

.bg-custom-primary {
  background-color: $primary;
}
.bg-custom-secondary {
  background-color: $secondary;
}
.text-custom-primary {
  color: $primary;
}
.text-custom-secondary {
  color: $secondary;
}
.btn-custom-primary {
  background-color: $primary !important;
  color: $secondary !important;
  padding: 8px 16px !important;
  border: none !important;
  &:hover {
    background-color: rgba($primary, 0.9) !important;
  }
}
.account-pages {
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

//
// backgrounds.scss
//
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    &.bg-soft {
      background-color: rgba(($value), 0.25) !important;
    }
  }
}

//
// Contacts.scss
//

.contact-links {
  a {
    color: $body-color;
  }
}

// profile

.profile-user-wid {
  margin-top: -26px;
}

//
// _card.scss
//

.card {
  margin-bottom: 24px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: 600;
}

.card-title-desc {
  color: $gray-600;
  margin-bottom: 24px;
}

//
// avatar.scss
//

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

// avatar group
.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
  .avatar-group-item {
    margin-left: -12px;
    border: 2px solid $card-bg;
    border-radius: 50%;
    transition: all 0.2s;
    &:hover {
      position: relative;
      transform: translateY(-2px);
    }
  }
}

//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    display: block;
  }
  .auth-logo-light {
    display: none;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="input"] + .btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

// authentication full page

.auth-full-bg {
  background-color: rgba($primary, 0.25);
  display: flex;

  @media (min-width: 1200px) {
    height: 100vh;
  }

  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .bg-overlay {
    background: url("../../../assets/images/bg-auth-overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.auth-full-page-content {
  display: flex;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0.25);
        }
        &.active,
        &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}
