// Bootstrap 4
@import "~bootstrap/dist/css/bootstrap.min.css";

@import "assets/scss/theme.scss";

// global styles
html,
body {
  background-color: #f8f8fb;
}
